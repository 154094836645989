import React, { createContext, useContext, useMemo, PropsWithChildren } from 'react';
import { io } from 'socket.io-client';
import { useSocketConnection } from 'components/SocketContainer/useSocketConnection';
import { SocketContextProps, SocketContextProviderProps } from './types';
import { isProd } from '../../common/consts';

const SocketContext = createContext({} as SocketContextProps);

export function useSocketContext(): SocketContextProps {
  const context = useContext<SocketContextProps>(SocketContext);
  if (!context) {
    throw new Error('useSocketContext must be used under SocketContextProvider');
  }
  return context;
}

export const SocketContextProvider = ({
  children,
  id,
  namespace,
  keyParam,
  path,
}: PropsWithChildren<SocketContextProviderProps>) => {
  const socket = useMemo(() => {
    const port = isProd ? `:${process.env.REACT_APP_WEBSOCKET_PORT}` : '';
    const ws = io(`wss://${process.env.REACT_APP_API_HOST}${port}${namespace}?${keyParam}=${id}`, {
      transports: ['websocket'],
      reconnectionAttempts: 10,
      autoConnect: false,
      path,
    });

    return { socket: ws };
  }, [namespace, keyParam, id, path]);

  useSocketConnection({ socket: socket.socket, id, namespace, keyParam });

  return <SocketContext.Provider value={socket}>{children}</SocketContext.Provider>;
};
