import React, { useMemo } from 'react';
import {
  Button,
  Flex,
  Link as MozaicLink,
  Loader,
  Notification,
  Text,
  View,
} from '@mozaic-ds/react';
import { Document48, DownloadWeb24 } from '@mozaic-ds/icons/react';
import FileUploaderIndicator from 'components/FileUploader/partials/Indicator';
import { useActionsModif } from 'pages/Request/partials/Modif/hooks';
import { useGetGrants } from 'pages/Request/hooks';
import { useAppTranslations } from 'translations';
import { gtmClickManageRequestButton } from 'pages/Request/partials/ActionButtons/gtm';
import { isLmEnvironment, isProd } from 'common/consts';
import FileUploaderLabel from 'components/FileUploader/partials/Label';
import cn from 'classnames';
import { useRequestData } from 'store/request/request';
import { FileType } from '@ppm/ppm-platform-file-service/build/enums';
import { ModifFile } from 'pages/Request/partials/Modif/types';
import { downloadFile } from 'common/utils';
import { getNotificationModifPropsByStatus } from 'pages/Request/partials/Modif/helpers';
import styles from './Modif.module.scss';

const ParsingStatus = {
  Success: 'success',
  InProgress: 'inProgress',
  Failed: 'failed',
};

type ModifProps = { id: string };

export const Modif = ({ id }: ModifProps) => {
  const translations = useAppTranslations();

  const {
    isLoading: isLoadingGeneration,
    downloadingModif,
    handleUploadModif,
  } = useActionsModif(id);
  const { hasRequestGrants } = useGetGrants();
  const { request } = useRequestData().data;
  const { RequestGenerateModif, RequestUploadModif } = hasRequestGrants;

  const modifFiles: ModifFile[] = useMemo(() => {
    return request
      ? (request.filesInfo.filter(
          (file) => file.fileType === FileType.ModifTemplate
        ) as ModifFile[])
      : [];
  }, [request]);

  return (
    <Flex
      marginTop="mu150"
      className={styles.wrapper}
      paddingTop="mu125"
      paddingBottom="mu125"
      paddingRight="mu200"
      paddingLeft="mu200"
      radius="l"
      shadow="s"
      direction="column"
    >
      <Flex justifyContent="space-between" marginBottom="mu125">
        <Text weight="semi-bold" theme="dark" size="l" as="p">
          {translations('modifFile')}
        </Text>
        <View>
          <Button
            size="s"
            variant="bordered"
            theme="neutral"
            leftIcon={isLoadingGeneration ? <Loader /> : <DownloadWeb24 />}
            isDisabled={!RequestGenerateModif || isLoadingGeneration}
            onClick={() => {
              if (isLmEnvironment && isProd)
                gtmClickManageRequestButton({
                  t: translations,
                  requestId: id,
                  buttonLabel: 'downloadModif',
                });
              downloadingModif();
            }}
          >
            {translations('downloadModif')}
          </Button>
          {RequestUploadModif && (
            <Text as="span">
              <FileUploaderLabel id="modif" className={cn(styles.smallSize, 'mu-ml-050')}>
                {translations('uploadModif')}
              </FileUploaderLabel>
              <FileUploaderIndicator
                id="modif"
                onChange={handleUploadModif}
                onClick={() => {
                  if (isLmEnvironment && isProd)
                    gtmClickManageRequestButton({
                      t: translations,
                      requestId: id,
                      buttonLabel: 'uploadModif',
                    });
                }}
              />
            </Text>
          )}
        </View>
      </Flex>
      {modifFiles.length > 0 && (
        <>
          <Flex>
            <Document48 fill="#188803" className="mu-mr-050" />
            <Flex direction="column" alignItems="flex-start">
              {modifFiles[0].status && modifFiles[0].status === ParsingStatus.Failed && (
                <Text weight="semi-bold" theme="danger">
                  {translations('willDeletedAfterStatusChange')}
                </Text>
              )}
              <Text>{modifFiles[0].name}</Text>
              <MozaicLink
                theme="primary"
                size="m"
                onClick={() => downloadFile(id, Number(modifFiles[0].id))}
              >
                {translations('download')}
              </MozaicLink>
            </Flex>
          </Flex>
          {modifFiles[0].status && (
            <Notification
              className="mu-mt-100"
              {...getNotificationModifPropsByStatus(translations, modifFiles[0])[
                modifFiles[0].status
              ]}
            />
          )}
        </>
      )}
    </Flex>
  );
};
