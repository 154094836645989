import React from 'react';
import styles from 'pages/Request/partials/Modif/Modif.module.scss';
import { Text } from '@mozaic-ds/react';
import { Translations } from 'translations';
import { ModifFile } from 'pages/Request/partials/Modif/types';
import { INotificationProps } from '@mozaic-ds/react/lib/components/Notification/Notification.types';
import { ParsingStatus } from '@ppm/ppm-platform-common';

export const getNotificationModifPropsByStatus = (
  t: Translations,
  modif: ModifFile
): Record<ParsingStatus, INotificationProps> => {
  const component = (
    <>
      {modif.messages.map((message, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <Text as="p" key={index}>
          {message}
        </Text>
      ))}
    </>
  );

  return {
    [ParsingStatus.Failed]: {
      title: t('modifErrorNotification'),
      className: `mu-mt-100 ${styles.notification}`,
      message: component,
      theme: 'danger',
    },
    [ParsingStatus.Success]: {
      title: t('downloadingModifIsSuccess'),
      theme: 'success',
    },
    [ParsingStatus.InProgress]: {
      title: t('downloadingModifIsPending'),
      theme: 'warning',
    },
  };
};
